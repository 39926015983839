<i18n>
{
  "en": {
    "helpCenter": "Help Center",
    "dashboard": "Dashboard"
  },
  "zh": {
    "helpCenter": "帮助中心",
    "dashboard": "仪表盘"
  },
  "kr": {
    "helpCenter": "지원 센터",
    "dashboard": "계기반"
  }
}
</i18n>

<template>
  <div>
    <Navbar />
    <router-view style="padding-top: 50px;"></router-view>
    <notifications position="top right" />
  </div>
</template>

<script>
import Navbar from "@/components/main/Navbar";

export default {
  name: "helpCenter",
  components: {
    Navbar
  },
  data() {
    return {
      isHamburgerClicked: false
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 0 5%;
  position: relative;
  align-items: center;
  display: flex;
  height: 71px;
  justify-content: space-between;

  article {
    display: flex;
    align-items: center;
    margin: 0 10px;
    text-transform: capitalize;
    font-size: 19px;
    padding: 14px;
    width: 80%;

    a {
      text-decoration: none;
    }
    img {
      height: 32px;
      margin-right: 5px;
      -webkit-animation: spin 7s linear infinite;
      -moz-animation: spin 7s linear infinite;
      animation: spin 7s linear infinite;
    }
  }
}

.desktop {
  display: flex;
  width: 100%;
}

.mobile .menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3999;
  height: 100vh;
  width: 280px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.15);
  padding: 70px 20px 0;
  -webkit-animation: slideIn .2s cubic-bezier(.65,.05,.36,1) forwards;
  animation: slideIn .2s cubic-bezier(.65,.05,.36,1) forwards;
}

.mobile {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger-input {
  opacity: 0;
}

.hamburger-input:checked ~ .hamburger-button > span {
  transform: rotate(45deg);
}
.hamburger-input:checked ~ .hamburger-button > span::before {
  top: 0;
  transform: rotate(0);
}
.hamburger-input:checked ~ .hamburger-button > span::after {
  top: 0;
  transform: rotate(90deg);
}

.hamburger-button {
  display: flex;
  /* align-items: center; */
  position: relative;
  /* top: 20px; */
  /* right: 20px; */
  z-index: 4000;
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.hamburger-button > span,
.hamburger-button > span::before,
.hamburger-button > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: .25s;
}
.hamburger-button > span::before {
  content: '';
  top: -8px;
}
.hamburger-button > span::after {
  content: '';
  top: 8px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dashboardBtn {
  border: 1px solid #7a77c5;
  border-radius: 1px;
  font-size: 16px;
  color:#7a77c5;
  padding: 7px;
  text-decoration: none;
  cursor: pointer;
}
</style>